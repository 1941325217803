<template>
  <div>
    <!-- bg -->
    <div class="bg_gray"></div>
    <headbox title="快递详情" bgColor="#fff" :customPath="true" path="pickUp" :query="{active:'b'}" isborder="true"></headbox>
    <div class="centerbox">
      <div class="">
        <!-- 快递物流追踪 -->
        <div class="msgbox rel">
          <img :src="arrowicon" alt="" class="arrownpx" />
          <h2 class="flex-between h2">
            <div>
              运单号：<b>{{ datalist.expressNo }}</b>
            </div>
          </h2>
          <div class="cityflex flex-between ">
            <div class="rel">
              <div class="cityname">
                <b>{{ datalist.fromCity }}</b>
                <i>{{
                  datalist.status === "TRANSPORT"
                    ? "运输中"
                    : datalist.status === "WAIT_SEND"
                    ? "待取件"
                    : datalist.status === "REFUSE"
                    ? "已拒绝"
                    : datalist.status === "CANCEL"
                    ? "已取消"
                    : datalist.status === "SIGNED"
                    ? "已签收"
                    : "待审核"
                }}</i>
                <b>{{ datalist.toCity }}</b>
              </div>
            </div>
            <div v-if="datalist.status == 'SIGNED'">
              <div class="citytitle">运单签收时间：</div>
              <div class="citytime">
                {{ datalist.signTime | formatDate_ }}
              </div>
            </div>
            <div v-else>
              <div class="citytitle">运单签收时间：</div>
              <div class="citytime">-</div>
            </div>
            <!-- <div>
            <div>
              西安市
            </div>
            <b>
              <i>待取件</i>
            </b>
            <div>
              深圳市
            </div>
          </div> -->
          </div>
        </div>
        <!-- 快递详情 -->
        <div class="flex-between peoplebox">
          <div class="headicon flex">
            <div class="imgbox">
              <img src="@/assets/images/peopleIcon.png" alt="" />
            </div>
            <div>{{ peoplephpone }}</div>
          </div>
          <div class="phoneicon">
            <a :href="'tel:' + peoplephpone">
              <img src="@/assets/images/phoneIcon.png" alt=""
            /></a>
          </div>
        </div>
        <div class="msgbox logtime">
          <div
            v-for="(items, index) in datalist.routes"
            :key="index"
            class="infolist"
          >
            <div class="flex logexpress">
              <div class="timebox">
                {{ arrtime[index].substring(0, 5) }}<br />{{
                  arrtime[index].substring(6, 20)
                }}
              </div>
              <!-- <div class="timebox">{{items.acceptTime | formatDate_}}</div> -->
              <div class="borderbox"><b></b></div>
              <div class="remarkbox">{{ items.remark }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headbox from "@/components/head.vue";
import * as expressApi from "@/utils/api";
import { formatDate_ } from "@/utils/filters";

export default {
  data() {
    return {
      arrowicon: require("@/assets/images/icon_yunsong.png"),
      datalist: "",
      // 时间节点
      arrtime: [],
      peoplephpone: ""
    };
  },
  methods: {
    geshihuatime(data) {
      const listdata_ = data.routes;
      for (const i in listdata_) {
        this.arrtime.push(
          formatDate_(listdata_[i].acceptTime).substring(11, 20) +
            " " +
            formatDate_(listdata_[i].acceptTime).substring(0, 10)
        );
      }
    }
  },
  filters: {
    formatDate_
  },
  mounted() {
    const oid_ = this.$route.query.id;
    expressApi.expressdetails(oid_).then(res => {
      this.datalist = res;
      console.log(res);
      if (res.state) {
        this.peoplephpone = res.state.empPhone;
      } else {
        this.peoplephpone = "-";
      }
      this.geshihuatime(this.datalist);
    });
  },
  components: {
    headbox
  }
};
</script>
<style lang="scss" scoped="true">
.arrownpx {
  width: 15%;
  // width: 120px;
  position: absolute;
  z-index: 5;
  left: 23%;
  top: 65%;
}
.logtime .infolist:nth-last-child(1) .borderbox:before {
  content: none;
}
.remarkbox {
  width: 430px;
  color: #8e8e90;
}
.borderbox {
  position: relative;
  b {
    width: 13px;
    height: 13px;
    background: #d7d7d7;
    display: block;
    border-radius: 50%;
    margin-right: 40px;
  }
  &::before {
    content: "";
    display: block;
    height: 90px;
    width: 2px;
    background: #d7d7d7;
    position: absolute;
    top: 0;
    left: 6px;
  }
}
.logtime {
  padding: 40px 20px 40px 0px !important;
}
.timebox {
  text-align: right;
  width: 150px;
  margin-right: 40px;
}
.cityname {
  position: relative;
  color: #000000;
  font-size: 32px;
  i {
    position: relative;
    top: -15px;
    color: #000000;
    font-style: normal;
    font-size: 26px;
    margin: 0 50px;
  }
}
.citytitle {
  color: #7a7a7a;
  font-size: 28px;
}
.citytime {
  color: #000000;
  font-size: 28px;
}
.peoplebox {
  border-radius: 8px;
  padding: 20px 40px 20px 20px;
  margin: 20px;
  background: #fff;
  .headicon {
    align-items: center;
    .imgbox {
      margin-right: 15px;
      width: 60px;
      height: 60px;
    }
  }
  .phoneicon {
    width: 45px;
    height: 45px;
  }
}
.canclebox {
  border: 1px solid #e6e6e6;
  color: #000000;
  width: 160px;
  height: 64px;
}
.flex {
  align-items: baseline;
}
.endflex {
  border-top: 1px solid #e6e6e6;
  padding-top: 20px;
  justify-content: flex-end;
  div {
    margin-left: 20px;
  }
}
.cityflex {
  padding: 0 30px;
}
.gosendproduct {
  background: #ffb606;
  color: #fff;
  border-radius: 32px;
  width: 160px;
  height: 64px;
  text-align: center;
  line-height: 64px;
  margin-right: 26px;
  font-size: 28px;
}
.flexs {
  display: flex;
}
.bg_gray {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #f7f7f7;
}
.centerbox {
  position: relative;
  z-index: 2;
}
.statusbox {
  color: #fc4e7f;
  font-size: 28px;
  border-radius: 8px;
  padding: 0 10px;
  font-weight: normal;
}
.onstatusbox {
  color: #fbac1e;
  font-size: 28px;
  border-radius: 8px;
  padding: 0 10px;
  font-weight: normal;
}
.overstatusbox {
  color: #999999;
  background: #f0f0f0;
  font-size: 28px;
  border-radius: 8px;
  padding: 0 10px;
  font-weight: normal;
}
.flex-between {
  align-items: center;
}

.yellowmin {
  color: #ffb606;
  border: 1px solid #ffb606;
  border-radius: 30px;
  font-size: 28px;
  width: 160px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  // margin-bottom: 30px;
}
.msgbox {
  background: #fff;
  padding: 0 0px 30px;
  width: 710px;
  font-size: 28ox;
  border-radius: 8px;
  margin: 25px auto 30px;

  h2 {
    color: #7a7a7a;
    padding: 0 30px 15px;
    font-size: 28px;
    margin-bottom: 30px;
    padding-top: 30px;
    b {
      color: #040202;
    }
  }
  .flex {
    margin-bottom: 30px;
    div:nth-child(1) {
      color: #cccccc;
    }
    div:nth-child(2) {
      color: #333333;
    }
  }
}
.tab_title {
  background: #f7f7f7;
  color: #999999;
  font-size: 26px;
  span {
    padding: 20px 0px 10px;
  }
  .active {
    position: relative;
    // color:#FFB606;
    &::after {
      content: "";
      width: 100%;
      height: 5px;
      border-radius: 4px;
      background: #ffb606;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
.iconbox {
  width: 125px;
}
.titlebox {
  font-size: 30px;
}
.msg_list {
  padding: 30px 0;
  border-bottom: 1px solid #cdcdcd;
  .time {
    position: absolute;
    right: 0;
    color: #b3b3b3;
    font-size: 24px;
    font-weight: normal;
    top: 35px;
    right: 30px;
  }
}
.details {
  font-size: 24px;
  margin-top: 15px;
  color: #b3b3b3;
}
</style>
