var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "bg_gray" }),
      _c("headbox", {
        attrs: {
          title: "快递详情",
          bgColor: "#fff",
          customPath: true,
          path: "pickUp",
          query: { active: "b" },
          isborder: "true"
        }
      }),
      _c("div", { staticClass: "centerbox" }, [
        _c("div", {}, [
          _c("div", { staticClass: "msgbox rel" }, [
            _c("img", {
              staticClass: "arrownpx",
              attrs: { src: _vm.arrowicon, alt: "" }
            }),
            _c("h2", { staticClass: "flex-between h2" }, [
              _c("div", [
                _vm._v(" 运单号："),
                _c("b", [_vm._v(_vm._s(_vm.datalist.expressNo))])
              ])
            ]),
            _c("div", { staticClass: "cityflex flex-between " }, [
              _c("div", { staticClass: "rel" }, [
                _c("div", { staticClass: "cityname" }, [
                  _c("b", [_vm._v(_vm._s(_vm.datalist.fromCity))]),
                  _c("i", [
                    _vm._v(
                      _vm._s(
                        _vm.datalist.status === "TRANSPORT"
                          ? "运输中"
                          : _vm.datalist.status === "WAIT_SEND"
                          ? "待取件"
                          : _vm.datalist.status === "REFUSE"
                          ? "已拒绝"
                          : _vm.datalist.status === "CANCEL"
                          ? "已取消"
                          : _vm.datalist.status === "SIGNED"
                          ? "已签收"
                          : "待审核"
                      )
                    )
                  ]),
                  _c("b", [_vm._v(_vm._s(_vm.datalist.toCity))])
                ])
              ]),
              _vm.datalist.status == "SIGNED"
                ? _c("div", [
                    _c("div", { staticClass: "citytitle" }, [
                      _vm._v("运单签收时间：")
                    ]),
                    _c("div", { staticClass: "citytime" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatDate_")(_vm.datalist.signTime)) +
                          " "
                      )
                    ])
                  ])
                : _c("div", [
                    _c("div", { staticClass: "citytitle" }, [
                      _vm._v("运单签收时间：")
                    ]),
                    _c("div", { staticClass: "citytime" }, [_vm._v("-")])
                  ])
            ])
          ]),
          _c("div", { staticClass: "flex-between peoplebox" }, [
            _c("div", { staticClass: "headicon flex" }, [
              _vm._m(0),
              _c("div", [_vm._v(_vm._s(_vm.peoplephpone))])
            ]),
            _c("div", { staticClass: "phoneicon" }, [
              _c("a", { attrs: { href: "tel:" + _vm.peoplephpone } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/phoneIcon.png"),
                    alt: ""
                  }
                })
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "msgbox logtime" },
            _vm._l(_vm.datalist.routes, function(items, index) {
              return _c("div", { key: index, staticClass: "infolist" }, [
                _c("div", { staticClass: "flex logexpress" }, [
                  _c("div", { staticClass: "timebox" }, [
                    _vm._v(" " + _vm._s(_vm.arrtime[index].substring(0, 5))),
                    _c("br"),
                    _vm._v(_vm._s(_vm.arrtime[index].substring(6, 20)) + " ")
                  ]),
                  _vm._m(1, true),
                  _c("div", { staticClass: "remarkbox" }, [
                    _vm._v(_vm._s(items.remark))
                  ])
                ])
              ])
            }),
            0
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgbox" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/peopleIcon.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "borderbox" }, [_c("b")])
  }
]
render._withStripped = true

export { render, staticRenderFns }